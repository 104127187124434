.heading {
  &-1 {
    display: flex;
    align-items: center;
    font-size: 5rem;
    letter-spacing: -0.03em;
    font-family: $font-secondary;
    @media only screen and (max-width: 56.25em) {
      font-size: 4.25rem;
    }
    @media only screen and (max-width: 37.5em) {
      font-size: 3.5rem;
    }
    span {
      margin-right: 1rem;
      background-image: linear-gradient(to right, #b0f3f1, #ffcfdf);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: inline-block;
    }
    &__sub {
      span {
        background-image: linear-gradient(
          to right,
          var(--color-white),
          var(--color-white)
        );
      }
    }
  }
  &-2 {
    font-size: 4.2rem;
    line-height: 105%;
    letter-spacing: -0.03em;
    color: var(--color-white);
    font-family: $font-secondary;
    @media only screen and (max-width: 57em) {
      line-height: 125%;
    }
    @media only screen and (max-width: 56.25em) {
      font-size: 3.5rem;
    }
    // @media only screen and (max-width: 37.5em) {
    //   font-size: 3.5rem;
    // }
  }
}

.alexxandria-anim {
  background: linear-gradient(
    -45deg,
    #63898a,
    #9fbcbf,
    var(--color-white),
    var(--color-white)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 400% 400%;
  line-height: 105%;
  animation: gradient 3s ease-in-out;

  @media only screen and (max-width: 57em) {
    line-height: 125%;
  }
}

.pixelchef-anim {
  background: linear-gradient(
    -45deg,
    #fbb034,
    #ffdd00,
    var(--color-white),
    var(--color-white)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 400% 400%;
  line-height: 105%;
  animation: gradient 3s ease-in-out;
}

.heatrow-anim {
  background: linear-gradient(
    -45deg,
    #045de9,
    #09c6f9,
    var(--color-white),
    var(--color-white)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 400% 400%;
  animation: gradient 3s ease-in-out;
}

.adeola-anim {
  background: linear-gradient(
    -45deg,
    var(--color-light-blue),
    var(--color-light-purple),
    var(--color-white),
    var(--color-white)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 400% 400%;
  line-height: 105%;
  animation: gradient 3s ease-in-out;
}

.about-text {
  grid-column: 2/14;
  font-size: 2.7rem;
  line-height: 130.4%;
  color: var(--color-gray);
  display: block;
  padding: 0 6rem;

  @media only screen and (max-width: 56.25em) {
    grid-column: 1/15;
    padding: 0 4rem;
    br {
      display: none;
    }
  }
}

.paragraph {
  font-size: 1.7rem;
  line-height: 130.4%;
  color: var(--color-gray);

  &__sub {
    color: #8b8b8b;
  }
}
