@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes logoLeftAnim {
  0% {
    transform: translateX(0%);
  }

  20% {
    transform: translate(-110%, 0);
  }

  50%,
  100% {
    transform: translate(0%, 0);
  }
}

@keyframes reviewCardAnim1 {
  from {
    transform: translateX(-52%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes reviewCardAnim2 {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-52%);
  }
}

@keyframes rect1Anim {
  0% {
    height: 5.8px;
  }
  50% {
    height: 8px;
  }
  100% {
    height: 1px;
  }
}
@keyframes rect2Anim {
  0% {
    height: 5.8px;
  }
  50% {
    height: 6px;
  }
  100% {
    height: 4px;
  }
}
@keyframes rect3Anim {
  0% {
    height: 5.8px;
  }
  50% {
    height: 4px;
  }
  100% {
    height: 8px;
  }
}
@keyframes rect4Anim {
  0% {
    height: 5.8px;
  }
  50% {
    height: 1px;
  }
  100% {
    height: 3px;
  }
}

@keyframes cursorAnim {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.rot {
  animation: cursorAnim 1s infinite linear;
}

@keyframes preloaderTextAnim {
  0% {
    transform: translateY(0%);
  }
  50%, 100% {
    transform: translateY(-600%);
  }
}