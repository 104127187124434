@font-face {
	font-family: "Circular Std Book";
	src: url('./fonts/CircularStd-Book.woff') format("woff");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "D-DIN Condensed";
	src: url('./fonts/D-DINCondensed-Bold.woff') format("woff");
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Mark Pro";
	src: url("./fonts/MarkPro-Heavy.woff") format("woff");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
	--color-white: #ffffff;
	--color-gray: #c4c4c4;
	--color-black: #000000;
	--color-purple: #8000ff;
	--color-light-purple: rgb(207, 89, 230);
	--color-light-blue: #6bc5f8;

  
	
}

*,
*::after,
*::before {
	font-family: var(--font-primary);
}
