.header-wrapper {
	display: grid;
	grid-template-columns: 1fr repeat(12, minmax(min-content, 10rem)) 1fr;
	position: relative;
	height: 100vh;
	// &::before {
	//   content: "";
	//   display: block;
	//   position: absolute;
	//   top: 0rem;
	//   left: 0rem;
	//   width: 26rem;
	//   height: 26rem;
	//   background-repeat: no-repeat;
	//   background-size: contain;
	//   background-image: url("../../public/webp/hand-left.webp");
	//   @media only screen and (max-width: 45em) {
	//     width: 20rem;
	//     height: 20rem;
	//   }
	// }
	// &::after {
	//   content: "";
	//   display: block;
	//   position: absolute;
	//   bottom: 7rem;
	//   right: 0rem;
	//   width: 25rem;
	//   height: 45rem;
	//   background-repeat: no-repeat;
	//   background-size: contain;
	//   background-image: url("../../public/webp/hand-right.webp");
	//   z-index: -1;

	//   @media only screen and (max-width: 45em) {
	//     width: 20rem;
	//     height: 40rem;
	//   }
	// }
}

.header {
	grid-column: 2/14;
	margin-top: 25rem;
	padding: 0 6rem;

	@media only screen and (max-width: 45em) {
		grid-column: 1/15;
		padding: 0 4rem;
	}

	&__hero {
		&--heading {
			font-family: $font-secondary;
			font-size: 9rem;
			line-height: 100%;
			letter-spacing: -0.03em;
			color: var(--color-white);
			position: relative;
			@media only screen and (max-width: 45em) {
				font-size: 7rem;
				br {
					display: none;
				}
			}
			@media only screen and (max-width: 28em) {
				font-size: 6rem;
			}

			&-gradient {
				background: linear-gradient(
					-45deg,
					var(--color-light-blue),
					var(--color-light-purple),
					var(--color-light-blue),
					var(--color-light-purple)
				);
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-size: 400% 400%;
				animation: gradient 3s ease infinite;
			}
		}
		&--cta {
			margin-top: 13rem;
			display: inline-block;
			font-family: $font-tertiary;
			font-size: 1.8rem;
			line-height: 92.4%;
			letter-spacing: 0.19em;
			color: var(--color-gray);
			text-decoration: none;
			position: relative;

			&::after {
				display: block;
				content: "";
				position: absolute;
				bottom: -1rem;
				left: 0;
				width: 50%;
				height: 1px;
				background: var(--color-gray);
				transition: all 0.3s ease;
			}

			&:hover {
				&::after {
					width: 100%;
				}
			}
		}
	}

	&__footer {
		grid-column: 1/15;
		padding: 0 6rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 16rem;
		margin-bottom: 14rem;
		color: var(--color-gray);

		@media only screen and (max-width: 57em) {
			padding: 0 4rem;
		}

		&--right {
			// width: 20rem;
			@include centerContent;
			display: flex;
			justify-content: space-between;
		}
		a {
			color: var(--color-gray);
			text-decoration: none;
			font-family: $font-tertiary;
			line-height: 92.4%;
			letter-spacing: -0.03em;
			text-transform: uppercase;
			transition: all 0.3s ease-in-out;
			display: flex;
			align-items: center;
			margin-right: 1rem;
			&:hover {
				color: var(--color-white);
			}

			& > img {
				width: 3rem;
			}
		}
	}
}

.profile-image {
	width: 30rem;
	height: 30rem;
	border-radius: 50%;
	grid-column: 1/15;
	margin: 5rem auto;
	position: relative;

	& > .profile-overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(115deg, #1a1e2b, #10101a, #1a1e2b, #10101a);
		opacity: 0.2;
		border-radius: 50%;
		background: linear-gradient(-45deg, var(--color-light-blue), #1a1e2b, var(--color-light-blue), #1a1e2b);
		background-size: 400% 400%;
		animation: gradient 3s ease infinite;
	}

	& > img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 50%;
	}
}

.about-text {
	margin-bottom: 3rem;
}

.gradient-text {
	background: linear-gradient(
		-45deg,
		var(--color-light-blue),
		var(--color-light-purple),
		var(--color-light-blue),
		var(--color-light-purple)
	);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-size: 400% 400%;
	animation: gradient 3s ease infinite;
}

// section-skills
.section-about-contact {
	grid-column: 2/14;
	padding: 0 6rem;
	margin-bottom: 12rem;

	@media only screen and (max-width: 57em) {
		grid-column: 1/15;
	}
	@media only screen and (max-width: 45em) {
		padding: 0 4rem;
	}

  & > h1{
    font-size: 4rem;
  }

	p {
		margin-top: 2rem;
	}

	&--links {
		// width: 30rem;
		@include centerContent;
		margin-top: 4rem;
		// justify-content: space-around;
		display: flex;
		flex-wrap: wrap;
    flex-direction: column;
		justify-content: flex-start;
    align-items: flex-start;

		@media (min-width: 1100px) {
			justify-content: flex-start;
		}
	}
	a {
		color: var(--color-gray);
		text-decoration: none;
		font-family: "D-DIN Condensed", sans-serif;
		/* line-height: 92.4%; */
		/* letter-spacing: -0.03em; */
		text-transform: lowercase;
		font-size: 5rem;
		font-weight: 100;
		transition: all 0.3s ease-in-out;
    // min-width: 30rem;
    margin: 1rem 0;
		display: flex;
    justify-content: space-between;

		& > svg, & > i {
      font-size: 3rem;
			width: 3rem;
			height: 3rem;
      color: var(--color-white);
      margin-right: 5rem;
		}

		& > h4 {
			font-size: 2.1rem;
		}

		&:hover {
			color: var(--color-white);
		}
	}
}

// section

.section-projects {
	grid-column: 2/14;
	margin-top: 20rem;
	padding: 0 6rem;

	@media only screen and (max-width: 57em) {
		grid-column: 1/15;
	}
	@media only screen and (max-width: 45em) {
		padding: 0 4rem;
	}

	p {
		margin-bottom: 15rem;
		margin-top: 2rem;
	}

	& > .project-card {
		width: 100%;
		margin: 5rem auto;
		padding: 4rem;
		display: flex;
		justify-content: space-between;
		color: var(--color-white); 
		background-color: #1c1c1c; 
		border-radius: 20px;
		position: relative;
		overflow: hidden;
		box-shadow: 0 10px 20px rgba(16, 16, 26, 0.25), 0 6px 6px rgba(16, 16, 26, 0.3);
		transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; 


		&:hover {
			transform: translateY(-10px);
			box-shadow: 0 15px 30px rgba(16, 16, 26, 0.3), 0 12px 10px rgba(16, 16, 26, 0.35); 
		}

		.project-card--badge {
				position: absolute;
				top: 10px; 
				right: 10px; 
				background-color: #f0ad4e; 
				color: #ffffff;
				padding: 5px 10px;
				border-radius: 10px;
				font-size: 12px;
				font-weight: bold;
				z-index: 10;
				box-shadow: 0px 2px 4px rgba(0,0,0,0.2);
			}

		& > .project-card--viewer {
			// border: 1px solid white;
			width: 60%;
			// min-height: 20rem;
			border-radius: .5rem;
			margin-right: 1rem;

			& > .project-card--tech-stack {
				display: flex;
				width: fit-content;
				align-items: center;
				margin: 3rem auto 0 auto;

				& > div{
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						margin-right: 2rem;

						& > h4{
							font-size: 2rem;
							font-weight: bold;
							background-image: linear-gradient(to right, #b0f3f1, #ffcfdf);
							background-clip: text;
							-webkit-background-clip: text;
							-webkit-text-fill-color: transparent;
							display: inline-block;
							// text-transform: uppercase;
						}

					& > i,
					& > svg,
					& > img {
						width: 5rem;
						height: 5rem;
						font-size: 5rem;
						background-image: linear-gradient(to right, #b0f3f1, #ffcfdf);
						background-clip: text;
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						display: inline-block;
					}

				}

				
			}
		}

		& > .project-card-info {
			width: 40%;
			display: flex;
			flex-direction: column;
			padding: 0 1rem;

			& > .project-card--name {
				color: var(--color-white);
				font-size: 4rem;
				font-weight: bold;
				text-align: center;

				margin-right: 1rem;
				background-image: linear-gradient(to right, #b0f3f1, #ffcfdf);
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				display: inline-block;
			}

			& > .project-card--details {
				list-style: none;
				margin: 1rem 0;

				& > .project-card--point {
					display: flex;
					align-items: flex-start;
					font-size: 2rem;
					margin: 1rem 0;

					& > svg {
						margin-top: .5rem;
						margin-right: 1rem;
					}
				}
			}

			& > .project-card-buttons {
				display: flex;
				width: 100%;
				text-align: center;

				& > a {
					font-size: 2rem;
					padding: 1rem 2rem;
					border: 1px solid var(--color-white);
					border-radius: 25rem;
					min-width: 15rem;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-right: 2rem;

					&:hover {
						border-image-source: var(--color-white);
					}
				}
			}
		}
	}

	@media (max-width: 1100px) {
		.project-card {
			flex-direction: column;
			& > .project-card--viewer,
			& > .project-card-info {
				width: 100%;
			}
		}
	}
}

// section-skills
.section-skills {
	grid-column: 2/14;
	padding: 0 6rem;
	margin-top: 12rem;
	margin-bottom: 12rem;

	@media only screen and (max-width: 57em) {
		grid-column: 1/15;
	}
	@media only screen and (max-width: 45em) {
		padding: 0 4rem;
	}

	p {
		margin-top: 2rem;
	}

	&--links {
		// width: 30rem;
		display: flex;
		flex-wrap: wrap;
		@include centerContent;
		margin-top: 4rem;
		// justify-content: space-around;
		justify-content: flex-start;

		@media (min-width: 1100px) {
			justify-content: flex-start;
		}
	}
	a {
		color: var(--color-gray);
		text-decoration: none;
		font-family: $font-tertiary;
		line-height: 92.4%;
		letter-spacing: -0.03em;
		text-transform: uppercase;
		font-size: 5rem;
		font-weight: 100;
		transition: all 0.3s ease-in-out;
		width: 22%;
		@media (max-width: 1100px) {
			width: 26%;
		}
		// width: 26%;
		// max-width: 35%;
		// max-width: 11rem;
		// margin: 4rem 4rem 0 0;
		margin: 3rem auto;
		display: flex;
		flex-direction: column;
		align-items: center;

		@media (max-width: 1100px) {
			margin: 2rem auto;
		}

		& > img {
			width: 9rem;
			height: 9rem;
		}

		& > h4 {
			font-size: 2.1rem;
			text-align: center;
		}

		&:hover {
			color: var(--color-white);
		}
	}
}

// section=reviews

.section-reviews__bg {
	background-color: #050810;
}

.section-reviews {
	grid-column: 1/15;
	display: grid;
	grid-template-columns: 1fr repeat(12, minmax(min-content, 10rem)) 1fr;
	padding: 8rem 0;
	transition: all 0.3s ease-in-out;
	&__top {
		grid-column: 2/14;
		padding: 0 6rem;

		@media only screen and (max-width: 57em) {
			grid-column: 1/15;
		}
		@media only screen and (max-width: 45em) {
			padding: 0 4rem;
		}

		p {
			margin-top: 1.5rem;
		}
	}

	&__bottom {
		grid-column: 1/15;
		overflow-x: hidden;
		margin-top: 8rem;
		margin-bottom: 4rem;
		&-wrapper {
			@include centerContent;
			width: max-content;
			.review-card {
				background: #171e2f;
				border-radius: 2rem;
				padding: 3rem;
				margin: 1.5rem;
				&__top {
					@include centerContent;
					margin-bottom: 3rem;

					&--right {
						& > img {
							width: 3rem;
							height: 3rem;
						}
					}
				}

				&__p {
					font-size: 0.8rem;
					text-transform: uppercase;
					line-height: 81.4%;
					letter-spacing: 0.25em;
					color: #585e6e;
				}

				&__h3 {
					font-size: 1.9rem;
					line-height: 81.4%;
					letter-spacing: -0.01em;
					color: #585e6e;
					margin-top: 1.5rem;
					font-weight: 100;
				}

				&__h2 {
					font-size: 2rem;
					line-height: 130.4%;
					color: var(--color-white);
					font-weight: 100;
				}
			}
		}
	}
}

.review-card__anim1 {
	animation: reviewCardAnim1 25s ease-in-out infinite alternate;

	&:hover {
		animation-play-state: paused;
	}
}

.review-card__anim2 {
	animation: reviewCardAnim2 25s ease-in-out infinite alternate;
	&:hover {
		animation-play-state: paused;
	}
}

// section-contact
.section-contact {
	grid-column: 2/14;
	padding: 0 6rem;
	margin-top: 12rem;

	@media only screen and (max-width: 57em) {
		grid-column: 1/15;
	}
	@media only screen and (max-width: 45em) {
		padding: 0 4rem;
	}

	&__h2 {
		font-size: 4.5rem;
		line-height: 130.4%;
		letter-spacing: -0.06em;
		color: var(--color-gray);
		font-weight: 100;
		margin-top: 4rem;

		@media only screen and (max-width: 56.25em) {
			font-size: 4rem;
		}
		@media only screen and (max-width: 37.5em) {
			font-size: 3rem;
		}

		a {
			color: var(--color-white);
			font-weight: bold;
			text-decoration: none;
			transition: all 0.3s ease-in-out;

			&:hover {
				color: var(--color-light-blue);
			}
		}
	}
}

// section-socials
.section-socials {
	grid-column: 2/14;
	padding: 0 6rem;
	margin-top: 12rem;

	@media only screen and (max-width: 57em) {
		grid-column: 1/15;
	}
	@media only screen and (max-width: 45em) {
		padding: 0 4rem;
	}

	p {
		margin-top: 2rem;
	}

	&--links {
		width: 30rem;
		@include centerContent;
		margin-top: 4rem;
	}
	a {
		color: var(--color-gray);
		text-decoration: none;
		font-family: $font-tertiary;
		line-height: 92.4%;
		letter-spacing: -0.03em;
		text-transform: uppercase;
		font-size: 3rem;
		font-weight: 100;
		transition: all 0.3s ease-in-out;
		&:hover {
			color: var(--color-white);
		}
	}
}

.footer {
	@include centerContent;
	width: 100%;
	margin-top: 12rem;
	padding: 3rem 6rem;
	border-top: 1px solid rgba(#c4c4c4, 0.25);

	@media only screen and (max-width: 45em) {
		padding-left: 4rem;
		padding-right: 4rem;
	}

	&__socials {
		@include centerContent;
		width: 10rem;
	}
}
